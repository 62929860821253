* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

.clearfix {
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    content: " ";
    display: table;
    clear: both;
  }

  *zoom: 1;
}

body {
  background-color: $base-background-color;
}

hr {
  border-top: none;
  border-bottom: 2px solid lighten($light-gray-color, 7%);
  width: 100%;
  margin: $small-spacing 0;
}

.container {
  padding: $base-spacing;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  font-weight: normal;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.link {
  color: $blue-color;
  padding-bottom: .05em;
  border-bottom: 2px solid transparent;

  @include transition(border 300ms linear);

  &:hover,
  &:focus,
  &:active {
    border-bottom: 2px solid $blue-color;
  }
}

.site-header {
  border-bottom: 1px solid lighten($light-gray-color, 5%);
  margin-bottom: $small-spacing;
  min-height: 80px;
}

.intro {
  img.avatar {
    float: left;
    margin-right: 20px;
  }

  .author-name {
    display: inline-block;
    padding: 0 .2em;
    background: lighten($light-gray-color, 15%)
  }

  a {
    @extend .link;
  }

  .container {
    padding-bottom: 0;
  }
}

.logo {
  float: left;
  margin: 0 0 1em 0;
  cursor: pointer;
  letter-spacing: 0.8px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 300;

  span {
    font-weight: 700;
  }
}

.nav {
  min-height: 64px;
}

.navbar {
  float: right;
  margin: 0;
  position: relative;
  padding: 0;
  pointer-events: all;
  cursor: pointer;

  li {
    display: inline-block;
    padding: 0 .6em;
  }

  a {
    @extend .link;
  }
}

.category-title {
  margin: 1em 0 0.5em 0;

  &:first-child {
    margin-top: 0;
  }
}

.post-item {
  margin-bottom: 0.2em;
  display: block;

  a {
    color: $action-color;
  }

  .article-title {
    display: inline-block;
    width: 85%;
    //border-bottom: 1px dashed lighten($light-gray-color, 10%);
  }

  .date-label {
    //border: 1px solid lighten($light-gray-color, 10%);
    padding: 0 0.2em;
    margin-left: 1em;
    margin-top: 2px;
    @extend .pull-right;
  }

}

.post-meta {
  color: $medium-gray-color;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  font-size: rem(14);
  margin-bottom: 1px;
}

.post-title {
  margin-top: 0;
  margin-bottom: 1em;
  color: $dark-gray-color;
}

.post-link,
.post a,
.page a {
  @extend .link;
}

.post {
  @extend .clearfix;
}

.pagination {
  li, a, span {
    display: inline-block;
  }

  a, span {
    font-size: rem(12);
    padding: .5em;
  }

  .prev, .next {
    @extend .link;
  }
}

.share {
  border-top: 0.2em dashed lighten($light-gray-color, 13%);
  padding-top: $base-spacing;
  margin-top: $base-spacing * 2;

  p {
    margin: 0 5em;
    font-size: rem(14);
    color: $medium-gray-color;
  }
}

.disqus {
  background-color: lighten($light-gray-color, 16%);
  padding: $base-spacing 0;

  hr {
    margin: $base-spacing 0;
  }
}

.site-footer {
  @extend .clearfix;

  padding: $base-spacing 0;

  a {
    @extend .link;
  }

  small {
    display: block;
    font-size: rem(12);
    color: darken($medium-gray-color, 10%);
  }

  .block {
    display: block;
  }
}

.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;

  iframe,
  object,
  embed {
    position:absolute;
  	top:0;
  	left:0;
  	width:100%;
  	height:100%;
  }
}

.divider {
  border-top: 0.2em solid lighten($light-gray-color, 13%);
  margin-bottom: $base-spacing * 2;
  margin-top: $base-spacing * 2;
}

// This applies from 0px to 600px
@media (max-width: 600px) {
  .date-label {
    float: left !important;
    border: 0 !important;
    margin-left: -2px !important;
    margin-top: 2px;
    margin-right: 1em;
  }

  .post-item {
    border: 1px solid lighten($light-gray-color, 10%);
    padding: 0 0.5em;
    background: lighten($light-gray-color, 15%);
  }

  .article-title {
    border-bottom: none !important;
  }

  .post-link {
    &:hover,
    &:focus,
    &:active {
      border-bottom: 2px solid transparent;
      color: $blue-color;
    }
  }
}
